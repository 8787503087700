import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import axiosInstance from "../../../app/axiosInstance";
import { prepareHeaders } from "../../auth/slice/AuthSlice";
import { handleApiError } from "../../common/utils/ErrorHandler";
import { PER_PAGE_SKU } from "../../../constants/pagination";
import {
  ADDITIONAL_INFO,
  ADDITIONAL_INFO_SUBMITTED,
  CANCELED,
  FINALIZED,
  IN_PROGRESS,
  IN_REVIEW,
  NOT_RESPONDED,
  REQUESTED,
} from "../../../constants/sku";

const skuUrl = "api/admin/sku";
const questionUrl = "api/admin/question";
const customerSkuUrl = "api/admin/customer-sku";

const fetchSkus = async (
  {
    customer_id = "",
    page = 1,
    code = "",
    filter_status = "",
    perPage = PER_PAGE_SKU,
  },
  { getState, rejectWithValue, dispatch }
) => {
  try {
    const state = getState();
    const response = await axiosInstance.get(`${skuUrl}/listingSku`, {
      params: {
        page,
        per_page: perPage,
        reference_number: code,
        customer_id,
        status: filter_status,
      },
      headers: prepareHeaders(state),
    });
    return response.data;
  } catch (error) {
    return handleApiError(error, rejectWithValue, dispatch);
  }
};

export const fetchNewSkus = createAsyncThunk(
  "skus/fetchNewSkus",
  (arg, { getState, rejectWithValue, dispatch }) =>
    fetchSkus(
      { filter_status: REQUESTED, ...arg },
      { getState, rejectWithValue, dispatch }
    )
);

export const fetchInProgressSkus = createAsyncThunk(
  "skus/fetchInProgressSkus",
  (arg, { getState, rejectWithValue, dispatch }) =>
    fetchSkus(
      {
        filter_status:
          IN_PROGRESS +
          "," +
          IN_REVIEW +
          "," +
          ADDITIONAL_INFO +
          "," +
          ADDITIONAL_INFO_SUBMITTED +
          "," +
          NOT_RESPONDED,
        ...arg,
      },
      { getState, rejectWithValue, dispatch }
    )
);

export const fetchCompletedSkus = createAsyncThunk(
  "skus/fetchCompletedSkus",
  (arg, { getState, rejectWithValue, dispatch }) =>
    fetchSkus(
      { filter_status: FINALIZED + "," + CANCELED, ...arg },
      { getState, rejectWithValue, dispatch }
    )
);

// Async thunk for fetching questionnaire
export const fetchQuestionnaire = createAsyncThunk(
  "skus/fetchQuestionnaire",
  async (id, { getState, rejectWithValue, dispatch }) => {
    try {
      const state = getState();
      const response = await axiosInstance.get(`${skuUrl}/showingSku/${id}`, {
        headers: prepareHeaders(state),
      });
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

export const updateComplianceCheckReport = createAsyncThunk(
  "skus/updateComplianceCheckReport",
  async ({ id, ...data }, { getState, rejectWithValue, dispatch }) => {
    try {
      const state = getState();
      const response = await axiosInstance.post(
        `${skuUrl}/update_result_of_compliance_check/${id}`,
        data,
        {
          headers: prepareHeaders(state),
        }
      );
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

export const updateComplianceAdditionalFee = createAsyncThunk(
  "skus/updateComplianceAdditionalFee",
  async (data, { getState, rejectWithValue, dispatch }) => {
    try {
      const state = getState();
      const response = await axiosInstance.post(
        `${skuUrl}/update_additional_fee_for_product`,
        data,
        {
          headers: prepareHeaders(state),
        }
      );
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

// Async thunk for fetching logs
export const fetchLogs = createAsyncThunk(
  "skus/fetchLogs",
  async (id, { getState, rejectWithValue, dispatch }) => {
    try {
      const state = getState();
      const response = await axiosInstance.get(`${customerSkuUrl}/log/${id}`, {
        headers: prepareHeaders(state),
      });
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

export const setSkuInReview = createAsyncThunk(
  "skus/setSkuInReview",
  async (id, { getState, rejectWithValue, dispatch }) => {
    try {
      const state = getState();
      const response = await axiosInstance.get(`${skuUrl}/in-review/${id}`, {
        headers: prepareHeaders(state),
      });
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

export const setSkuInAdditionalInfo = createAsyncThunk(
  "skus/setSkuInAdditionalInfo",
  async (id, { getState, rejectWithValue, dispatch }) => {
    try {
      const state = getState();
      const response = await axiosInstance.get(
        `${questionUrl}/update-status/additional-info/${id}`,
        {
          headers: prepareHeaders(state),
        }
      );
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

export const setSkuInFinalized = createAsyncThunk(
  "skus/setSkuInFinalized",
  async ({ code, id }, { getState, rejectWithValue, dispatch }) => {
    try {
      const state = getState();
      const response = await axiosInstance.get(
        `${skuUrl}/in-finalized/${code}/${id}`,
        {
          headers: prepareHeaders(state),
        }
      );
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

export const setSkuInCanceled = createAsyncThunk(
  "skus/setSkuInCanceled",
  async ({ id, data }, { getState, rejectWithValue, dispatch }) => {
    try {
      const state = getState();
      const response = await axiosInstance.post(
        `${skuUrl}/canceled/${id}`,
        data,
        {
          headers: prepareHeaders(state),
        }
      );
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

export const pickSku = createAsyncThunk(
  "skus/pickSku",
  async (id, { getState, rejectWithValue, dispatch }) => {
    try {
      const state = getState();
      const response = await axiosInstance.get(`${skuUrl}/pick/${id}`, {
        headers: prepareHeaders(state),
      });
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

export const leaveSku = createAsyncThunk(
  "skus/leaveSku",
  async ({ id, data }, { getState, rejectWithValue, dispatch }) => {
    try {
      const state = getState();
      const response = await axiosInstance.post(`${skuUrl}/leave/${id}`, data, {
        headers: prepareHeaders(state),
      });
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);
export const countSku = createAsyncThunk(
  "skus/countSku",
  async (_, { getState, rejectWithValue, dispatch }) => {
    try {
      const state = getState();
      const response = await axiosInstance.get(`${skuUrl}/count-sku-status`, {
        headers: prepareHeaders(state),
      });
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

const SkuSlice = createSlice({
  name: "sku",
  initialState: {
    newSkuLoading: false,
    newSkus: [],
    newSkuFilter: "",
    newSkuPageCount: 0,
    newSkuCurrentPage: 0,
    newSkuPerPage: PER_PAGE_SKU,
    new_sku_code: "",
    new_sku_filter_status: "",
    inProgressLoading: false,
    inProgressSkus: [],
    inProgressFilter: "",
    inProgressPageCount: 0,
    inProgressCurrentPage: 0,
    inProgressPerPage: PER_PAGE_SKU,
    in_progress_code: "",
    in_progress_filter_status: "",
    completedLoading: false,
    completedSkus: [],
    completedFilter: "",
    completedPageCount: 0,
    completedCurrentPage: 0,
    completedPerPage: PER_PAGE_SKU,
    completed_code: "",
    completed_filter_status: "",
    updatingComplianceCheckReport: false,
    updatingComplianceAdditionalFee: false,
    loading: false,
    isModalLoading: false,
    isQuestionLoading: false,
    isLogLoading: false,
    isCountLoading: false,
    skus: [],
    questions: [],
    logs: [],
    sku: null,
    firstData: null,
    pageCount: 0,
    currentPage: 0,
    skuCountDetail: null,
    code: "",
    filter_status: "",
    perPage: PER_PAGE_SKU,
    error: "",
    validationError: [],
  },
  reducers: {
    setNewSkuFilter: (state, action) => {
      state.new_sku_code = action.payload;
      state.newSkuCurrentPage = 0;
    },
    setNewSkuFilterStatus: (state, action) => {
      state.new_sku_filter_status = action.payload;
      state.newSkuCurrentPage = 0;
    },
    setNewSkuPage: (state, action) => {
      state.newSkuCurrentPage = action.payload;
    },
    setNewSkuPerPage: (state, action) => {
      state.newSkuPerPage = action.payload;
      state.newSkuCurrentPage = 0;
    },
    clearNewSkuError: (state) => {
      state.newSkuError = "";
    },
    setInProgressSkuFilter: (state, action) => {
      state.in_progress_code = action.payload;
      state.inProgressCurrentPage = 0;
    },
    setInProgressSkuFilterStatus: (state, action) => {
      state.in_progress_filter_status = action.payload;
      state.inProgressCurrentPage = 0;
    },
    setInProgressSkuPage: (state, action) => {
      state.inProgressCurrentPage = action.payload;
    },
    setInProgressSkuPerPage: (state, action) => {
      state.inProgressPerPage = action.payload;
      state.inProgressCurrentPage = 0;
    },
    clearInProgressSkuError: (state) => {
      state.inProgressError = "";
    },
    setCompletedSkuFilter: (state, action) => {
      state.completed_code = action.payload;
      state.completedCurrentPage = 0;
    },
    setCompletedSkuFilterStatus: (state, action) => {
      state.completed_filter_status = action.payload;
      state.completedCurrentPage = 0;
    },
    setCompletedSkuPage: (state, action) => {
      state.completedCurrentPage = action.payload;
    },
    setCompletedSkuPerPage: (state, action) => {
      state.completedPerPage = action.payload;
      state.completedCurrentPage = 0;
    },
    clearCompletedSkuError: (state) => {
      state.completedError = "";
    },
    setFilter: (state, action) => {
      state.code = action.payload;
      state.currentPage = 0;
    },
    setFilterStatus: (state, action) => {
      state.filter_status = action.payload;
      state.currentPage = 0;
    },
    setFirstData: (state, action) => {
      state.firstData = action.payload;
    },
    setPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setPerPage: (state, action) => {
      state.perPage = action.payload;
      state.currentPage = 0;
    },
    setSku: (state, action) => {
      state.sku = action.payload;
    },
    clearError: (state) => {
      state.error = "";
    },
    clearValidationError: (state) => {
      state.validationError = [];
    },
  },
  extraReducers: (builder) => {
    builder
      // fetch new skus
      .addCase(fetchNewSkus.pending, (state) => {
        state.newSkuLoading = true;
        state.newSkuError = "";
      })
      .addCase(fetchNewSkus.fulfilled, (state, action) => {
        state.newSkuLoading = false;
        const payload = action.payload?.payload;
        state.newSkus = payload?.data;
        state.newSkuPageCount = payload?.last_page;
        state.newSkucurrentPage = payload?.current_page - 1;
      })
      .addCase(fetchNewSkus.rejected, (state, action) => {
        state.newSkuLoading = false;
        state.newSkuError = action.payload;
      })
      // fetch in progress skus
      .addCase(fetchInProgressSkus.pending, (state) => {
        state.inProgressLoading = true;
        state.inProgressError = "";
      })
      .addCase(fetchInProgressSkus.fulfilled, (state, action) => {
        state.inProgressLoading = false;
        const payload = action.payload?.payload;
        state.inProgressSkus = payload?.data;
        state.inProgressPageCount = payload?.last_page;
        state.inProgresscurrentPage = payload?.current_page - 1;
      })
      .addCase(fetchInProgressSkus.rejected, (state, action) => {
        state.inProgressLoading = false;
        state.inProgressError = action.payload;
      })
      // fetch completed skus
      .addCase(fetchCompletedSkus.pending, (state) => {
        state.completedLoading = true;
        state.completedError = "";
      })
      .addCase(fetchCompletedSkus.fulfilled, (state, action) => {
        state.completedLoading = false;
        const payload = action.payload?.payload;
        state.completedSkus = payload?.data;
        state.completedPageCount = payload?.last_page;
        state.completedcurrentPage = payload?.current_page - 1;
      })
      .addCase(fetchCompletedSkus.rejected, (state, action) => {
        state.completedLoading = false;
        state.completedError = action.payload;
      })
      // fetch detail of questionnaire
      .addCase(fetchQuestionnaire.pending, (state) => {
        state.isQuestionLoading = true;
        state.error = "";
      })
      .addCase(fetchQuestionnaire.fulfilled, (state, action) => {
        state.isQuestionLoading = false;
        let payload = action.payload?.payload;
        state.sku = payload;
        state.questions = payload?.questions;
        if (payload?.questions && payload?.questions[0]) {
          state.firstData = payload?.questions[0];
        }
      })
      .addCase(fetchQuestionnaire.rejected, (state, action) => {
        state.isQuestionLoading = false;
        state.error = action.payload;
      })
      .addCase(fetchLogs.pending, (state) => {
        state.isLogLoading = true;
        state.error = "";
      })
      .addCase(fetchLogs.fulfilled, (state, action) => {
        state.isLogLoading = false;
        state.logs = action.payload.payload;
      })
      .addCase(fetchLogs.rejected, (state, action) => {
        state.isLogLoading = false;
        state.error = action.payload;
      })
      // update compliance check report
      .addCase(updateComplianceCheckReport.pending, (state) => {
        state.updatingComplianceCheckReport = true;
        state.validationError = [];
      })
      .addCase(updateComplianceCheckReport.fulfilled, (state, action) => {
        state.updatingComplianceCheckReport = false;
      })
      .addCase(updateComplianceCheckReport.rejected, (state, action) => {
        state.updatingComplianceCheckReport = false;
        if (typeof action.payload !== "string") {
          state.validationError = action.payload?.errors ?? [];
        }
      })
      // updating compliance additional fee
      .addCase(updateComplianceAdditionalFee.pending, (state) => {
        state.updatingComplianceAdditionalFee = true;
        state.validationError = [];
      })
      .addCase(updateComplianceAdditionalFee.fulfilled, (state, action) => {
        state.updatingComplianceAdditionalFee = false;
        state.validationError = [];
      })
      .addCase(updateComplianceAdditionalFee.rejected, (state, action) => {
        state.updatingComplianceAdditionalFee = false;
        if (typeof action.payload !== "string") {
          state.validationError = action.payload?.errors ?? [];
        }
      })
      // set sku status to in review
      .addCase(setSkuInReview.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(setSkuInReview.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(setSkuInReview.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // set sku status to additional info
      .addCase(setSkuInAdditionalInfo.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(setSkuInAdditionalInfo.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(setSkuInAdditionalInfo.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // set sku status to approve
      .addCase(setSkuInFinalized.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(setSkuInFinalized.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(setSkuInFinalized.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // set sku status to cancel
      .addCase(setSkuInCanceled.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(setSkuInCanceled.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(setSkuInCanceled.rejected, (state, action) => {
        state.loading = false;
        if (typeof action.payload !== "string") {
          state.validationError = action.payload.errors;
        }
      })
      // pick sku
      .addCase(pickSku.pending, (state) => {
        state.isModalLoading = true;
        state.error = "";
      })
      .addCase(pickSku.fulfilled, (state, action) => {
        state.isModalLoading = false;
        let data = action.payload.payload;
        const index = state.skus.findIndex((sku) => sku.id === data.id);
        if (index !== -1) {
          state.skus[index] = data;
        }
      })
      .addCase(pickSku.rejected, (state, action) => {
        state.isModalLoading = false;
      })
      // leave sku
      .addCase(leaveSku.pending, (state) => {
        state.isModalLoading = true;
        state.error = "";
      })
      .addCase(leaveSku.fulfilled, (state, action) => {
        state.isModalLoading = false;
        let data = action.payload.payload;
        const index = state.skus.findIndex((sku) => sku.id === data.id);
        if (index !== -1) {
          state.skus[index] = data;
        }
      })
      .addCase(leaveSku.rejected, (state, action) => {
        state.isModalLoading = false;
      })
      // count requested sku
      .addCase(countSku.pending, (state) => {
        state.isCountLoading = true;
        state.error = "";
      })
      .addCase(countSku.fulfilled, (state, action) => {
        state.isCountLoading = false;
        state.skuCountDetail = action.payload.payload;
      })
      .addCase(countSku.rejected, (state, action) => {
        state.isCountLoading = false;
      });
  },
});

export const {
  setNewSkuFilter,
  setNewSkuFilterStatus,
  setNewSkuPage,
  setNewSkuPerPage,
  clearNewSkuError,
  setInProgressSkuFilter,
  setInProgressSkuFilterStatus,
  setInProgressSkuPage,
  setInProgressSkuPerPage,
  clearInProgressSkuError,
  setCompletedSkuFilter,
  setCompletedSkuFilterStatus,
  setCompletedSkuPage,
  setCompletedSkuPerPage,
  clearCompletedSkuError,
  setFirstData,
  clearValidationError,
  clearError,
  setSku,
} = SkuSlice.actions;

export default SkuSlice.reducer;
