import React, { lazy, useEffect, useState } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import "./App.css";
import { fetchProfile } from "./modules/auth/slice/AuthSlice";

// Check for login and initialize axios
const Login = lazy(() => import("./modules/auth/Login"));
const Layout = lazy(() => import("./modules/containers/Layout"));

function App() {
  const language = useSelector((state) => state.language.language);
  const { i18n } = useTranslation(); // eslint-disable-next-line
  const [key, setKey] = useState(0);
  const { isAuthenticated } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    i18n.changeLanguage(language).then(() => {
      setKey((prevKey) => prevKey + 1);
    });
    if (isAuthenticated) {
      dispatch(fetchProfile());
    }
  }, [language, i18n, isAuthenticated, dispatch]);

  return (
    <div key={language}>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />} />
          {/* Place new routes over this */}
          <Route path="/admin/*" element={<Layout />} />
          <Route
            path="*"
            element={
              <Navigate
                to={isAuthenticated ? "/admin/dashboard" : "/login"}
                replace
              />
            }
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
