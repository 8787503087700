import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import axiosInstance from '../../../app/axiosInstance';
import { prepareHeaders } from '../../auth/slice/AuthSlice';
import { handleApiError } from '../../common/utils/ErrorHandler';
import { PER_PAGE_CATEGORY } from '../../../constants/pagination';

const categoryUrl = 'api/admin/question-category';

export const fetchCategories = createAsyncThunk(
  'categories/fetchCategories',
  async ({ page = 1, filter = '', perPage = PER_PAGE_CATEGORY }, { getState, rejectWithValue, dispatch }) => {
    try {
      const state = getState();
      const response = await axiosInstance.get(`${categoryUrl}/index`, {
        params: { page, per_page: perPage, search: filter },
        headers: prepareHeaders(state)
      });
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

export const fetchCategoriesList = createAsyncThunk(
  'categories/fetchCategoriesList',
  async ({ search = '' }, { getState, rejectWithValue, dispatch }) => {
    try {
      const state = getState();
      let params = [];
      if (search !== '') {
        params['search'] = search;
      }
      const response = await axiosInstance.get(`${categoryUrl}/list`, {
        params,
        headers: prepareHeaders(state)
      });
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

// Async thunk for fetching category
export const fetchCategory = createAsyncThunk('categories/fetchCategory', async (id, { getState, rejectWithValue, dispatch }) => {
  try {
    const state = getState();
    const response = await axiosInstance.get(`${categoryUrl}/show/${id}`, {
      headers: prepareHeaders(state),
    });
    return response.data;
  } catch (error) {
    return handleApiError(error, rejectWithValue, dispatch);
  }
});

export const createCategory = createAsyncThunk(
  'categories/createCategory',
  async (newCategory, { getState, rejectWithValue, dispatch }) => {
    try {
      const state = getState();
      const response = await axiosInstance.post(`${categoryUrl}/store`, newCategory, {
        headers: prepareHeaders(state)
      });
      return response.data;
    } catch (error) {
      return handleApiError(error, rejectWithValue, dispatch);
    }
  }
);

// Async thunk for updating category
export const updateCategory = createAsyncThunk('categories/updateCategory', async (category, { getState, rejectWithValue, dispatch }) => {
  try {
    const state = getState();
    let categoryData = state.category.category;
    const response = await axiosInstance.post(`${categoryUrl}/update/${categoryData.id}`, category, {
      headers: prepareHeaders(state),
    });
    return response.data;
  } catch (error) {
    return handleApiError(error, rejectWithValue, dispatch);
  }
});

// Async thunk for deleting category
export const deleteCategory = createAsyncThunk('categories/deleteCategory', async (id, { getState, rejectWithValue, dispatch }) => {
  try {
    const state = getState();
    const response = await axiosInstance.delete(`${categoryUrl}/destroy/${id}`, {
      headers: prepareHeaders(state),
    });
    return response.data;
  } catch (error) {
    return handleApiError(error, rejectWithValue, dispatch);
  }
});

const categorySlice = createSlice({
  name: 'category',
  initialState: {
    loading: false,
    isModalLoading: false,
    categories: [],
    category: null,
    categoryListData: [],
    pageCount: 0,
    currentPage: 0,
    filter: '',
    perPage: PER_PAGE_CATEGORY,
    error: '',
    validationError: []
  },
  reducers: {
    setFilter: (state, action) => {
      state.filter = action.payload;
      state.currentPage = 0;
    },
    setPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setPerPage: (state, action) => {
      state.perPage = action.payload;
      state.currentPage = 0;
    },
    clearError: (state) => {
      state.error = '';
    },
    clearValidationError: (state) => {
      state.validationError = [];
    }
  },
  extraReducers: (builder) => {
    builder
      // fetch categories
      .addCase(fetchCategories.pending, (state) => {
        state.loading = true;
        state.error = '';
      })
      .addCase(fetchCategories.fulfilled, (state, action) => {
        state.loading = false;
        state.categories = action.payload.payload.data;
        state.pageCount = action.payload.payload.last_page;
        state.currentPage = action.payload.payload.current_page - 1;
      })
      .addCase(fetchCategories.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // fetch categories list
      .addCase(fetchCategoriesList.pending, (state) => {
        state.loading = true;
        state.error = '';
      })
      .addCase(fetchCategoriesList.fulfilled, (state, action) => {
        state.loading = false;
        state.categoryListData = action.payload.payload;
      })
      .addCase(fetchCategoriesList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // create category
      .addCase(createCategory.pending, (state) => {
        state.isModalLoading = true;
        state.error = '';
      })
      .addCase(createCategory.fulfilled, (state, action) => {
        state.isModalLoading = false;
        state.categories = [action.payload.payload, ...state.categories];
      })
      .addCase(createCategory.rejected, (state, action) => {
        state.isModalLoading = false;
        if (typeof action.payload !== 'string') {
          state.validationError = action.payload.errors;
        }
      })
      // Fetch category
      .addCase(fetchCategory.pending, (state) => {
        state.isModalLoading = true;
        state.error = null;
      })
      .addCase(fetchCategory.fulfilled, (state, action) => {
        let data = action.payload.payload;
        state.category = data;
        state.isModalLoading = false;
      })
      .addCase(fetchCategory.rejected, (state, action) => {
        state.isModalLoading = false;
        state.error = action.payload;
      })
      // update category
      .addCase(updateCategory.pending, (state) => {
        state.isModalLoading = true;
        state.error = null;
      })
      .addCase(updateCategory.fulfilled, (state, action) => {
        let data = action.payload.payload;
        const index = state.categories.findIndex(category => category.id === data.id);
        if (index !== -1) {
          state.categories[index] = data;
        }
        state.category = data;
        state.isModalLoading = false;
      })
      .addCase(updateCategory.rejected, (state, action) => {
        state.isModalLoading = false;
        if (typeof action.payload !== 'string') {
          state.validationError = action.payload.errors;
        }
      })
      // delete category
      .addCase(deleteCategory.pending, (state) => {
        state.isModalLoading = true;
        state.error = null;
      })
      .addCase(deleteCategory.fulfilled, (state, action) => {
        state.category = null;
        state.isModalLoading = false;
      })
      .addCase(deleteCategory.rejected, (state, action) => {
        state.isModalLoading = false;
        state.error = action.payload;
      });
  }
});

export const { setFilter, setPage, setPerPage, clearError, clearValidationError } = categorySlice.actions;

export default categorySlice.reducer;
