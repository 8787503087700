// axiosInstance.js
import axios from "axios";

import { API_BASE_URL } from "../constants/app";

const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  // timeout: 10000, // Set a timeout for the requests
});

// Retry logic
// axiosInstance.interceptors.response.use(
//   response => response,
//   async error => {
//     const { config, response: { status } } = error;
//     const MAX_RETRIES = 3;

//     if (status === 429 && !config.__retryCount) {
//       config.__retryCount = 0;
//     }

//     if (status === 429 && config.__retryCount < MAX_RETRIES) {
//       config.__retryCount += 1;
//       const delay = Math.pow(2, config.__retryCount) * 1000; // Exponential backoff
//       await new Promise(res => setTimeout(res, delay));
//       return axiosInstance(config);
//     }

//     return Promise.reject(error);
//   }
// );

export default axiosInstance;
